<template>
    <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loaddata="loaddata()"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
         <v-card class="elevation-0" :color="color.BG">
            <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>
        
        <br/>
        <v-flex class="pl-3" lg6 xs12>
        <v-card
        class="mx-auto"
        max-width="600"
       >
  <v-card-title v-if="$t('default') === 'th'">{{title_th}}&nbsp;{{first_name_th}}</v-card-title>
  <v-card-title v-else>{{first_name_eng}}&nbsp;{{title_en}}</v-card-title>
    <v-card-text>
      <div><v-icon x-small>mdi-email-outline</v-icon>&nbsp;{{$t('admin.indexemail')}} {{email}}</div>
      <p><v-icon x-small>mdi-phone</v-icon>&nbsp;{{$t('admin.indexmobile')}} {{mobile}}</p>
      
    </v-card-text>
    <!-- <v-card-actions><v-btn @click="loaddata()"></v-btn></v-card-actions> -->
  </v-card>
  </v-flex>
  </v-card>
    </v-content>
    </v-app>    
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";

import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
    components:{
     onebox_admin_toolbar,
    },
    data :function(){
    return{
      loaddataprogress:false,
      size: 50,
      page: 1,
      title_en:"",
      title_th:"",
      email:"",
      first_name_th:"",
      first_name_eng:"",
      mobile:"",

    }
    },
    computed: {
    ...mapState(["username", "authorize", "account_active", "service","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax"
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.rootfile.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      }
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    showdatafilter() {
      // console.log(search);
      return this.rootfile.filter(item => {
        return (
          item.file_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },headerTable(){
      return 'color:'+this.color.theme+';'+'font-weight: 600; font-size:15px;'
    },headerPage(){
      // console.log(this.color.theme);
      
      return 'color:'+this.color.theme+';'+'font-size: 18px; font-weight: 600;'
    },colorVapp(){
      return 'background:'+this.color.BG+';'
    },colorSort(){
      return 'color:'+this.color.alertText+';'
    },colorProgress(){
      return 'color:'+this.color.theme+';'
    },   
    loaddatacom(){
  this.title_en = this.dataAccountActive.business_info.account_title_eng;
  this.title_th = this.dataAccountActive.business_info.account_title_th;
  this.email = this.dataAccountActive.business_info.email;
  this.first_name_th = this.dataAccountActive.business_info.first_name_th;
  this.first_name_eng = this.dataAccountActive.business_info.first_name_eng;
  this.mobile = this.dataAccountActive.business_info.mobile;
    }
 },created(){
   //window.addEventListener('beforeunload', this.someMethod)
  this.loaddata();
  this.title_en = this.dataAccountActive.business_info.account_title_eng;
  this.title_th = this.dataAccountActive.business_info.account_title_th;
  this.email = this.dataAccountActive.business_info.email;
  this.first_name_th = this.dataAccountActive.business_info.first_name_th;
  this.first_name_eng = this.dataAccountActive.business_info.first_name_eng;
  this.mobile = this.dataAccountActive.business_info.mobile;
 },
 methods:{
   someMethod(event){
      this.$router.push({ name: "myfiles" })
      console.log("test");
      
    },
    loaddata(){
        console.log("dd",this.dataAccountActive.business_info.account_title_eng);
        this.title_en = this.dataAccountActive.business_info.account_title_eng;
        this.title_th = this.dataAccountActive.business_info.account_title_th;
        this.email = this.dataAccountActive.business_info.email;
        this.first_name_th = this.dataAccountActive.business_info.first_name_th;
        this.first_name_eng = this.dataAccountActive.business_info.first_name_eng;
        this.mobile = this.dataAccountActive.business_info.mobile;
    }
 },mounted(){
     this.loaddata();
     this.title_en = this.dataAccountActive.business_info.account_title_eng;
     this.title_th = this.dataAccountActive.business_info.account_title_th;
     this.email = this.dataAccountActive.business_info.email;
     this.first_name_th = this.dataAccountActive.business_info.first_name_th;
     this.first_name_eng = this.dataAccountActive.business_info.first_name_eng;
     this.mobile = this.dataAccountActive.business_info.mobile;
 }
}
</script>
<style>
.pointer {
  cursor: pointer;
}
/* #thiscontainerindexadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
} */
</style>
